import { IoCodeWorking, IoLogoFacebook, IoLogoGithub, IoLogoLinkedin } from "react-icons/io5";
import img1 from "./images/firstProject.png";
import img2 from "./images/secondProject.png";
import img3 from "./images/thirdProject.png";

export const Experience = [
    {
        id: 1,
        date:"August 2020 - present",
        iconsSrc: <IoCodeWorking />,
        title:"Computer Science Student",
        location:"University of Texas at Arlington",
        description:"Bachelor of Science in Computer Science",
    },
    {
        id:2,
        date:"January 2022 - May 2022",
        iconsSrc: <IoCodeWorking />,
        title:"Teaching Assistant",
        location:"Truc Viet Organization",
        description:"Teaching Assistant in Vietnamese Language Class",
    },
]

export const Projects = [
    {
        id: 1,
        name: "Recipe Book Android App",
        imageSrc: img1,
        techs: "Java, Firebase, XML",
        github: "#",
    },
    {
        id: 2,
        name: "The Knight",
        imageSrc: img2,
        techs: "C#, C++, Javascript",
        github: "#",
    },
    {
        id: 3,
        name: "T-Shirt Designing Website",
        imageSrc: img3,
        techs: "nextJs, React",
        github: "#",
    }
];

export const SocialLinks = [
    {
        id: 1,
        iconsSrc: <IoLogoGithub className="text-textBase text-3xl cursor-pointer" />,
        name: "GitHub",
        link: "https://github.com/TheOnlyK141v",
    },
    {
        id: 2,
        iconsSrc: <IoLogoLinkedin className="text-blue-500 text-3xl cursor-pointer" />,
        name: "Linkedin",
        link: "https://www.linkedin.com/in/khanh-ha-b38633291/",
    },
    {
        id: 3,
        iconsSrc: <IoLogoFacebook className="text-blue-400 text-3xl cursor-pointer" />,
        name: "Facebook",
        link: "https://www.facebook.com/namkhanh.ha.716",
    }
]