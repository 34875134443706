import Spline from "@splinetool/react-spline";
import { useState } from "react";
import {IoMenu, IoLogoGithub } from 'react-icons/io5';

import Khanh from './images/me.png';
import { 
  VerticalTimeline, 
  VerticalTimelineElement 
}  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Experience, Projects, SocialLinks } from "./data";

import { AnimatePresence, motion } from 'framer-motion';

function App() {
  const [isActive, setIsActive] = useState(false)

  return (
    <AnimatePresence initial={false}>
      <div className="flex w-screen min-h-screen flex-col items-center justify-center relative bg-primary pb-20">
        {/*Navigation Bar */}
        <nav className="w-full px-6 z-50 fixed inset-x-0 top-2 flex justify-center items-center">
          <div className="w-full md:w-880 bg-navBar p-4 rounded-2xl flex items-center">
            <p className="text-lg text-slate-200 font-medium">Khanh Ha</p>

            <div className="hidden md:flex items-center gap-6 ml-6 flex-1">
              <motion.a 
              whileTap={{scale: 0.7}}
              href="#home" 
              className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out" onClick={() =>  setIsActive(false)}
              >
                Home
              </motion.a>

              <motion.a 
              whileTap={{scale: 0.7}}
              href="#about" 
              className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out" onClick={() =>  setIsActive(false)}
              >
                About
              </motion.a>

              <motion.a 
              whileTap={{scale: 0.7}}
              href="#projects" 
              className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out" onClick={() =>  setIsActive(false)}
              >
                Projects
              </motion.a>

              <motion.a 
              whileTap={{scale: 0.7}}
              href="#contact" 
              className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out" onClick={() =>  setIsActive(false)}
              >
                Contact
              </motion.a>

              <motion.a 
              whileTap={{scale: 0.8}}
              href="/downloads/Resume New.pdf" 
              download="Resume New.pdf" 
              className="ml-auto text-base text-textBase font-medium hover:text-slate-100 cursor-pointer border border-textBase px-2 py-1 rounded-xl hover:border-gray-100 duration-100 ease-in-out"
              >
                Download
              </motion.a>
            </div>

            <motion.div 
            whileTap={{scale: 0.5}}
            className="block md:hidden ml-auto cursor-pointer" 
            onClick={() => setIsActive(!isActive)}
            >
              <IoMenu className="text-2xl text-textBase" />
            </motion.div>

            {isActive && (
              <motion.div 
                initial={{opacity: 0, scale: 0.5}}
                animate={{opacity: 1, scale: 1.1}}
                exit={{opacity: 0, scale: 0.5}}
                transition={{delay: 0.1, type: "spring"}}
                className="p-4 w-275 bg-navBar rounded-lg fixed top-24 right-16 flex flex-col items-center justify-evenly gap-6">
                  <a 
                  href="#home" 
                  className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out" 
                  onClick={() => setIsActive(false)}
                  >
                    Home
                  </a>

                  <a 
                  href="#about" 
                  className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out" 
                  onClick={() => setIsActive(false)}
                  >
                    About
                  </a>

                  <a 
                  href="#projects" 
                  className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out" 
                  onClick={() => setIsActive(false)}
                  >
                    Projects
                  </a>

                  <a 
                  href="#contact" 
                  className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out" 
                  onClick={() => setIsActive(false)}
                  >
                    Contact
                  </a>

                  <motion.a whileTap={{scale: 0.8}}
                  href="/downloads/Resume New.pdf" 
                  download="Resume New.pdf"
                  className="ml-auto text-base text-textBase font-medium hover:text-slate-100 cursor-pointer border border-textBase px-2 py-1 rounded-xl hover:border-gray-100 duration-100 ease-in-out"
                  onClick={() => setIsActive(false)}
                  >
                    Download
                  </motion.a>
              </motion.div>
            )}
          </div>
        </nav>

        <div className="relative w-full h-screen md:h-auto" id="home">
          <Spline scene="https://prod.spline.design/Dw4lemzXmP8N0Y2Y/scene.splinecode"/>

          <div className="absolute bottom-10 w-full justify-center items-center flex">
            <div className="shadow-md p-4 flex items-center justify-center bg-zinc-900 rounded-3xl">
              <p className="text-textBase">Press and Drag to Orbit</p>
            </div>
          </div>
        </div>

        {/* Main Sections */}

        <main className="w-[80%] mt-5">
          {/* About Sections */}
            <section 
            className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 my-24" 
            id="about"
            >
              {/* Image Box */}
              <div className="w-full h-420 flex items-center justify-center">
                <div className="w-275 h-340 bg-pink-300 rounded-md relative">
                  <img 
                  className="w-full h-full absolute -right-4 top-4 object-cover rounded-lg shadow-lg" 
                  src={Khanh} 
                  alt="" 
                  />
                </div>
              </div>

              {/* Content Box */}
              <div className="w-full h-420 flex flex-col items-center justify-center">
                <p className="text-lg text-textBase text-center">
                  Hello. My name is Khanh Ha. I am a future 
                  full-stack developer, and right now I am a 
                  student at The University of Texas at Arlington.
                </p>

                <motion.a href="/downloads/Resume New.pdf" download="Resume New.pdf" whileTap={{scale: 0.8}} class="cursor-pointer w-full md:w-auto relative mt-6 inline-flex items-center justify-center p-0.5 mb-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-pink-200 to-purple-400 group-hover:from-pink-400 group-hover:to-purple-600 hover:text-white dark:text-white focus:ring-4 focus:ring-pink-200 dark:focus:ring-pink-800 hover:shadow-lg hover:shadow-rose-500/50 hover:dark:shadow-lg hover:dark:shadow-rose-800/80">
                  <span class="w-full md:w-auto relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                    Download
                  </span>
                </motion.a>
              </div>
            </section>
          {/* Timeline */}
            <section className="w-full flex items-center justify-center">
              <VerticalTimeline>
              {
                Experience && 
                  Experience.map(n => (
                    <VerticalTimelineElement
                    key={n.id}
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'rgb(255, 153, 153)', color: '#dc143c' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgb(255, 153, 153)' }}
                    date={n.date}
                    iconStyle={{ background: 'rgb(255, 153, 153)', color: '#dc143c' }}
                    icon={n.iconsSrc}
                    >
                      <h3 className="vertical-timeline-element-title">{n.title}</h3>
                      <h4 className="vertical-timeline-element-subtitle">{n.location}</h4>
                      <p>
                        {n.description}
                      </p>
                    </VerticalTimelineElement>
                  ))
              }
              </VerticalTimeline>
            </section>


            {/* Projects Section */}
            <section className="flex flex-wrap items-center justify-evenly my-24 gap-4" id="projects">
              {Projects && Projects.map(n => (
                <div key={n.id} className="border border-zinc-800 rounded-md p-2 min-w-[275px] md:max-w-[275px] hover:border-zinc-600 duration-100 ease-in-out">
                <p className="text-md text-textBase font-medium uppercase">
                  {n.name.Length > 25 ? `$(n.name.slice(0,25))}...` : n.name}
                </p>
                <img 
                src={n.imageSrc} 
                className="w-full h-full object-cover rounded-md my-4" 
                alt="" 
                />

              <div className="flex flex-1 items-center justify-between">
                <p className="text-lg text-gray-300">
                  Technologies
                  <span className="block text-sm text-gray-500">
                    {n.techs}
                  </span>
                </p>
                <a href="n.github">
                  <motion.div whileTap={{scale: 0.7}}>
                    <IoLogoGithub className="text-textBase text-3xl cursor-pointer" />
                  </motion.div>
                </a>
              </div>
              </div>
              ))}  
            </section>
            {/*Contact */}
            <section id="contact" className="flex flex-col items-center justify-center w-full my-24">
                <p className="text-2xl text-gray-400 capitalize">Follow me on</p>
                <div className="flex items-center justify-center w-full my-4 flex-wrap gap-4">
                  {
                    SocialLinks && SocialLinks.map(n => (
                      <motion.a whileTap={{scale: 0.7}} key={n.id}
                      href={n.link} 
                      className="w-full md:w-auto px-3 md:px-8 py-5 border border-zinc-800 rounded-2xl hover:border-zinc-600 duration-100 ease-in-out cursor-pointer flex items-center justify-center gap-3">
                    {n.iconsSrc}
                    <p className="text-lg text-textBase">{n.name}</p>
                  </motion.a>
                    ))
                  }
                </div>
            </section>
        </main>
      </div>
    </AnimatePresence>
  );
}

export default App;